import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



// import required modules
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';

const menu = [
    {
        id: 1,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0001.jpg?alt=media&token=7b78a090-8a59-4c04-96e3-57698d6c5475',
        color: '#00b6c6'
    },
    {
        id: 2,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0002.jpg?alt=media&token=914521c8-329b-443e-a3e3-9bd1dbd6357e',
        // color: '#006f79'
    },
    {
        id: 3,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0003.jpg?alt=media&token=0bcfaf6c-a958-4520-ac88-46c0a6764226',
        // color: '#004a52'
    },
    {
        id: 4,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0004.jpg?alt=media&token=ad0b7816-0c27-425b-8803-75cd987060b5',
        // color: '#8d5e32'
    },
    {
        id: 5,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0005.jpg?alt=media&token=e9c703f4-8eed-404f-82a7-476698747847',
        // color: '#004a52'
    },
    {
        id: 6,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0006.jpg?alt=media&token=1a63e297-07ea-441c-8323-5821c511ebbb',
        // color: '#8d5e32'
    },
    {
        id: 7,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0007.jpg?alt=media&token=80aee81a-a619-4720-84ce-03ae005b5fe6',
        // color: '#004a52'
    },
    {
        id: 8,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0008.jpg?alt=media&token=c43ce35c-9cf6-43e5-b61a-ab32361b8e27',
        // color: '#8d5e32'
    },
    {
        id: 9,
        img: 'https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Menu_ElConro%2FMenu%20-%20Restaurante%20el%20Conrro_page-0009.jpg?alt=media&token=8e3515ab-a50c-4efa-aaaf-477c1bd9632f',
        // color: '#8d5e32'
    },
]

export default function App() {
  return (
    <>
        <div>
            <h2 className="text-center py-4">Menu</h2>
        </div>
      <div className="h-full w-full">      
        <Swiper
          slidesPerView={1}
          centeredSlides={false}
          slidesPerGroupSkip={1}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          breakpoints={{
            769: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
          }}
          scrollbar={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Keyboard, Scrollbar, Navigation, Pagination]}
          className="mySwiper w-[50%] justify-center text-center flex items-center"
        >
          {menu.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className="h-full bg-center bg-cover rounded"
              >
                <img className="" src={item.img} alt={item.title} />
                <span className='text-center justify-center'>Page {item.id}</span>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
